import { useEffect, useState } from "react";
import { PageProps } from "../../layouts/Page/Types/Page";
import BoxIcon from "../../assets/images/icon_box.svg";
import MessageIcon from "../../assets/images/icon_message.svg";
import ImgGroupPending from "../../assets/images/img_process_pending.svg";
import ImgGroupDelivering from "../../assets/images/img_process_delivering.svg";
import ImgGroupDelivered from "../../assets/images/img_process_finish.svg";
import OrderAPI from "../../api/Order/Order.api";
import { OrderModal } from "../../modal/order.modal";
import moment from "moment";
import Divider from "@mui/material/Divider/Divider";

interface SendMessageList {
  [key: string]: string;
}

const EnquiryPage = ({ t }: PageProps) => {
  const [isSearched, setIsSearched] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [orderList, setOrderList] = useState<OrderModal[]>([]);
  const [messageList, setMessageList] = useState<SendMessageList>({});

  const [enableBtnList, setEnableBtnList] = useState<number[]>([]);

  const onClickSearch = () => {
    if (searchKeyword.length > 0) {
      setIsSearched(true);
      try {
        OrderAPI.orderList(
          { searchKeyword: searchKeyword },
          async (data) => {
            if (data.data) {
              setOrderList(data.data);
            }
          },
          () => {}
        );
      } catch (e) {}
    }
  };

  const onClickSendMessage = (id: number) => {
    if (searchKeyword.length > 0) {
      setIsSearched(true);

      if (
        messageList &&
        messageList[`${id}`] &&
        messageList[`${id}`].length > 0
      ) {
        try {
          OrderAPI.sendMessageToDriver(
            id,
            messageList[`${id}`],
            async (data) => {
              if (data.data === true) {
                showSuccessDialog();
              } else {
                showErrorDialog();
              }
            },
            () => {}
          );
        } catch (e) {
          showErrorDialog();
        }
      }
    }
  };

  const showSuccessDialog = () => {
    alert(`${t("search_order_detail_message_successfull")}`);
  };

  const showErrorDialog = () => {
    alert(`${t("search_order_detail_message_failed")}`);
  };

  const onInputTextChange = (id: number, text: string) => {
    const newMessageList = messageList;
    newMessageList[`${id}`] = text;
    setMessageList(newMessageList);
    const newEnableBtnList = [...enableBtnList];
    if (text.length > 0 && !newEnableBtnList.includes(id)) {
      newEnableBtnList.push(id);
      setEnableBtnList(newEnableBtnList);
    } else if (newEnableBtnList.includes(id) && text.length === 0) {
      setEnableBtnList(newEnableBtnList.filter((e) => e !== id));
    }
  };

  const getStatusProcessUI = (status: string, devlierDate: string) => {
    if (status === "PENDING") {
      let isSameDate = false;
      if (devlierDate === `${moment().format("MM/DD")}`) {
        isSameDate = true;
      }
      return (
        <div className="d-flex justify-content-center">
          <img
            className="process-img"
            src={isSameDate ? ImgGroupDelivering : ImgGroupPending}
            alt=""
          />
        </div>
      );
    } else if (status === "DELIVERED") {
      return (
        <div className="d-flex justify-content-center">
          <img className="process-img" src={ImgGroupDelivered} alt="" />
        </div>
      );
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="text-center mt-5 bg-theme-main rounded w-100 h-100 p-4 d-flex flex-column justify-content-center mb-5">
      <div className="col-12 text-theme-blue fonts font-32 weight-700">
        {t("search_title")}
      </div>
      <div className="col-12 mt-2">
        <div className="input-group mb-3 p-lg-5">
          <input
            type="text"
            className="form-control"
            placeholder={t("search_placeholder_enquiry")}
            aria-label={t("search_placeholder_enquiry")}
            aria-describedby="basic-addon2"
            onChange={(event) => {
              setSearchKeyword(event.target.value);
            }}
          />
          <div className="input-group-append">
            <button
              className="app-btn rounded-right-5 text-white bg-theme-main-blue"
              type="button"
              onClick={onClickSearch}
            >
              {t("search_button_title")}
            </button>
          </div>
        </div>
      </div>
      {isSearched ? (
        <div className="text-start p-3 text-black">
          <div className="mb-4">
            <img className="icon me-2" src={BoxIcon} alt="" />
            <div className="d-inline-block">{t("search_my_package")}</div>
          </div>
          <Divider sx={{ height: "2px", backgroundColor: "black" }} />

          <div>
            {orderList.length > 0 ? (
              orderList.map((e, index) => {
                return (
                  <div className="p-4" key={e.id}>
                    <div className="fonts font-16 p-2 weight-900">
                      {t("search_order_detail_title")}
                    </div>
                    <div className="fonts font-16 p-2">
                      {t("search_order_detail_id_title")}
                      <span className="ps-2">{e.orderId}</span>
                    </div>
                    <div className="fonts font-16 p-2">
                      {t("search_order_detail_date_title")}
                      <span className="ps-2">
                        {e?.secondDeliverDate ?? e.deliverDate}
                      </span>
                    </div>
                    <div className="mt-5">
                      <div className="text-center mb-3">
                        {t("serach_order_detail_status_title")}
                      </div>
                      <div>
                        {getStatusProcessUI(
                          e.status,
                          e.secondDeliverDate ?? e.deliverDate
                        )}
                      </div>
                    </div>
                    {e.status !== "DELIVERED" &&
                      ((e?.deliverDate ?? "") ===
                        `${moment().format("MM/DD")}` ||
                        (e?.secondDeliverDate ?? "") ===
                          `${moment().format("MM/DD")}`) && (
                        <div className="mt-5">
                          <div className="mb-4">
                            <img
                              className="icon me-2"
                              src={MessageIcon}
                              alt=""
                            />
                            <div className="d-inline-block">
                              {t("search_contact_driver")}
                            </div>
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              id={`input-message-${e.id}`}
                              rows={5}
                              onChange={(event) => {
                                onInputTextChange(e.id, event.target.value);
                              }}
                              placeholder={t(
                                "search_contact_driver_field_placeholder"
                              )}
                            ></textarea>
                          </div>

                          <div className="d-flex justify-content-center mt-4 mb-3">
                            <button
                              className={`app-btn rounded ${
                                enableBtnList.includes(e.id)
                                  ? "bg-theme-main-blue text-white"
                                  : "bg-secondary text-dark"
                              }`}
                              disabled={!enableBtnList.includes(e.id)}
                              type="button"
                              onClick={() => {
                                onClickSendMessage(e.id);
                              }}
                            >
                              {t("search_button_title")}
                            </button>
                          </div>
                        </div>
                      )}
                    {index !== orderList.length - 1 && (
                      <Divider
                        sx={{
                          height: "2px",
                          backgroundColor: "black",
                          marginTop: "10px",
                        }}
                      />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="fonts font-18 text-center p-4">
                {t("search_no_result")}
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EnquiryPage;
